const messages = {
    se: {
      title: 'Nyarp Bygg AB',
      sidebarTitle: 'Nyarp Bygg AB',
    },
    en: {
      title: 'Nyarp Bygg AB',
      sidebarTitle: 'Nyarp Bygg AB',
    },
  };

  export default messages; 
  